import { UserProvider } from "@auth0/nextjs-auth0/client";
import { Tooltip } from "@compoundfinance/design-system";
// This is the only frontend workflow where we want to use this import.
// eslint-disable-next-line no-restricted-imports
import { HydrationBoundary, QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import type { AppProps } from "next/app";
import { useState } from "react";
// When SSRing some pages (ex: the clients page), we need regenerator for the async calls to work
import "regenerator-runtime/runtime";
import NotificationProvider from "../providers/NotificationProvider";
import Notification from "../providers/NotificationProvider/Notification";
import "../styles/globals.css" assert { type: "css" };
import { Monitoring } from "feature/monitoring/Monitoring";

const ControApp = ({
  Component,
  pageProps: { ...pageProps },
}: AppProps<{
  dehydratedState: unknown;
}>) => {
  const [queryClient] = useState(() => new QueryClient());

  return (
    <UserProvider>
      <QueryClientProvider client={queryClient}>
        <Monitoring />
        <HydrationBoundary state={pageProps.dehydratedState}>
          <NotificationProvider>
            <Tooltip.Provider>
              <ReactQueryDevtools position="bottom" />
              <Component {...pageProps} />
              <Notification />
            </Tooltip.Provider>
          </NotificationProvider>
        </HydrationBoundary>
      </QueryClientProvider>
    </UserProvider>
  );
};

export default ControApp;
